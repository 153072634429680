<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="d-flex" style="align-items: center;">
              <i class="fa fa-solid fa fa-bullhorn " aria-hidden="true" style="font-size: 24px; margin-right: 3px; color: var(--iq-primary);"></i>
              <h4 class="card-title ml-2">{{cvCardTitle}}</h4>
            </div>
          </template>
          <template v-slot:headerAction>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <!-- Frontend Search -->
              <!-- <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>Frontend Search -->

              <!-- Backend Search -->
              <b-col class="col-12 col-sm-8 col-md-5 col-lg-4 mb-2">
                <b-form-input class="form-control"
                  @input="searchcustomFormBuildWithParams"
                  v-model="search_param"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col><!-- Backend Search -->
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="customFormBuildObjList && customFormBuildObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="customFormBuildObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentBatchNo"
                  :per-page="dataPerPage"
                >
                  <template v-slot:cell(cfb_id)="data">
                    {{ data.item.cfb_id }}
                    <b-button variant="mr-1 mb-1 primary-color" @click="doCopy(data.item.cfb_id)" title="Copy Form Id"><i class="fa-solid fa-copy pointer" aria-hidden="true" ></i></b-button><br />
                    <b-button variant="mr-1 mb-1 primary-color" @click="viewForm(data.item.cfb_id)" title="View Form"><i class="fa fa-eye font-size-20" aria-hidden="true" ></i></b-button>
                    <b-button variant="mr-1 mb-1 primary-color" @click="customFormBuildEdit('MODAL', data.item)" title="Edit Form" v-if="!data.item.editable"><i class="fa fa-pencil font-size-20 m-0"></i></b-button>
                    <b-button variant="mr-1 mb-1 primary-color" @click="gotoFinalFormURL(data.item.cfb_id)" title="View Final Link"><i class="fa fa fa-chrome font-size-20"></i></b-button>
                    <b-button variant="mr-1 mb-1 primary-color" @click="showcustomFormBuildDeleteDialog(data.item)" title="Delete Form"><i class="fa fa-trash-o font-size-20"></i></b-button>
                  </template>

                  <template v-slot:cell(form_subs_id)="data">
                    <span class="primary-color pointer" @click="gotoSubscriptionEdit(data.item.form_subs_id)">{{data.item.form_subs_id}}</span>
                  </template>

                  http://localhost:14433/subscriptionPlan_edit/CONTACTUSB2BV1
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-col sm="12" md="12" class="my-1">
                <b-row>
                  <b-col sm="12" md="7">
                    <b-btn variant="primary" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
                  </b-col>
                  <b-col sm="5" md="5">
                    <b-pagination
                      v-model="currentBatchNo"
                      :total-rows="totalRows"
                      :per-page="dataPerPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-col>
              <b-modal
                v-model="showModelcustomFormBuildEdit"
                scrollable
                :title="cvEditModalHeader"
              >
                <customFormBuildEdit :propOpenedInModal="true" :propcustomFormBuildObj="customFormBuildEditObj" @emitClosecustomFormBuildEditModal="closecustomFormBuildEditModal" />
              <template #modal-footer="">
                <b-button size="sm" class="pull-left" @click="customFormBuildEdit('FULLPAGE')">
                  {{cvbtnModalExpand}}
                </b-button>
                <b-button size="sm" @click="closecustomFormBuildEditModal()">
                  {{cvbtnModalCancel}}
                </b-button>
              </template>
              </b-modal>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal v-model="showModelcustomFormBuildDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelcustomFormBuildDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="customFormBuildDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { customFormBuilds } from "../../../FackApi/api/customFormBuild.js"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit.js"
import customFormBuildEdit from "./customFormBuildEdit.vue"

export default {
  name: "customFormBuildList",
  components: {
    customFormBuildEdit
  },
  data () {
    return {
      cvCardTitle: "Custom Forms",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Custom Form",
      cvAddModalHeader: "Add Custom Form",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Custom Form Build",
      showModelcustomFormBuildAdd: false,
      showModelcustomFormBuildEdit: false,
      showModelcustomFormBuildDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "Form Id", key: "cfb_id", class: "text-left align-text-top w-300px", sortable: true },
        { label: "Form Title", key: "form_title", class: "text-left align-text-top", sortable: true },
        { label: "Form DB", key: "form_table_name", class: "text-left align-text-top", sortable: true },
        { label: "Subscritption Plan", key: "form_subs_id", class: "text-left align-text-top", sortable: true },
        { label: "Configuration", key: "form_config", class: "text-left align-text-top", sortable: true }
      ],
      customFormBuildObjList: [],
      customFormBuildEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 365)), // From Last Year
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        }
      },
      search_param: "",
      currentBatchNo: 1,
      dataPerPage: 25,
      showLoadMoreBtn: true
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  async mounted () {
    await this.getDateFilter()
    socialvue.index()
    this.customFormBuildList()
  },
  methods: {
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
    */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter = storeDate
      }
    },
    /**
     * searchcustomFormBuildWithParams
     */
    async searchcustomFormBuildWithParams () {
      if (this.whereFilter.search_param.length == 0) {
        this.showLoadMoreBtn = true
      }
      this.currentBatchNo = 1
      this.customFormBuildList()
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.customFormBuildList(true)
    },
    /**
     * Calling Function as per whereFilter
    */
    async getcustomFormBuildDateWise () {
      this.customFormBuildList()
      await this.setDateFilter()
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * Do Copy
     */
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")
      document.body.appendChild(dummy)
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
    },
    /**
     * customFormBuildList
     */
    async customFormBuildList (loadViaLoadMore = false) {
      try {
        // setting data for pagination
        this.whereFilter.currentBatchNo = this.currentBatchNo
        this.whereFilter.dataPerPage = this.dataPerPage
        this.whereFilter.search_param = this.search_param

        let customFormBuildListResp = await customFormBuilds.customFormBuildList(this, this.whereFilter)
        if (customFormBuildListResp.resp_status) {
          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.customFormBuildObjList = [ ...customFormBuildListResp.resp_data.data ]
          }
          else {
            this.customFormBuildObjList = [ ...this.customFormBuildObjList, ...customFormBuildListResp.resp_data.data ]
          }
          this.showLoadMoreBtn = true

          // Adding the serial numbers:
          this.customFormBuildObjList.forEach((s, index) => { s.id = index + 1 })
          this.totalRows = this.customFormBuildObjList.length
        }
        else {
          this.showLoadMoreBtn = false
          this.currentBatchNo = this.currentBatchNo - 1
          this.toastMsg = customFormBuildListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at customFormBuildList() and Exception:", err)
      }
    },
    /**
     * customFormBuildEdit
     */
    customFormBuildEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("customFormBuild_edit/" + this.customFormBuildEditObj.cfb_id)
        }
        else {
          this.customFormBuildEditObj = item
          this.formatJSON(this.customFormBuildEditObj.form_config)
          this.showModelcustomFormBuildEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at customFormBuildEdit() and Exception:", err.message)
      }
    },
    /**
     * goto Subscription Editor
     */
    async gotoSubscriptionEdit (subsPlanId) {
      let url = `subscriptionPlan_edit/${subsPlanId}`
      window.open(url, "_blank")
    },
    /**
     * goto the Final Form URL
     */
    async gotoFinalFormURL (cfbId) {
      let url = `Activity/${cfbId}`
      window.open(url, "_blank")
    },
    /**
     * showcustomFormBuildDeleteDialog
     */
    showcustomFormBuildDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelcustomFormBuildDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showcustomFormBuildDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * customFormBuildDelete
     */
    async customFormBuildDelete () {
      try {
        let customFormBuildDelResp = await customFormBuilds.customFormBuildDelete(this, this.delObj.cfb_id)
        await ApiResponse.responseMessageDisplay(this, customFormBuildDelResp)

        if (customFormBuildDelResp && !customFormBuildDelResp) {
          this.showModelcustomFormBuildDelete = false
          return false
        }

        let index = this.customFormBuildObjList.indexOf(this.delObj)
        this.customFormBuildObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelcustomFormBuildDelete = false
      }
      catch (err) {
        console.error("Exception occurred at customFormBuildDelete() and Exception:", err.message)
      }
    },
    /**
     * closecustomFormBuildAddModal
     */
    closecustomFormBuildAddModal (customFormBuildAddData) {
      try {
        if (customFormBuildAddData) {
          if (this.customFormBuildObjList && this.customFormBuildObjList.length >= 1) {
            let customFormBuildObjLength = this.customFormBuildObjList.length
            let lastId = this.customFormBuildObjList[customFormBuildObjLength - 1]["id"]
            customFormBuildAddData.id = lastId + 1
          }
          else {
            this.customFormBuildObjList = []
            customFormBuildAddData.id = 11111
          }

          customFormBuildAddData.created_on = moment()
          this.customFormBuildObjList.unshift(customFormBuildAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelcustomFormBuildAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closecustomFormBuildAddModal() and Exception:", err.message)
      }
    },
    /**
     * formatJSON
     */
    formatJSON (unformattedJSON = null) {
      try {
        if (!unformattedJSON) {
          unformattedJSON = document.getElementById("json").value
        }
        let obj = JSON.parse(unformattedJSON)
        let formattedJSON = JSON.stringify(obj, undefined, 4)
        this.customFormBuildEditObj.form_config = formattedJSON
      }
      catch (err) {
        this.toastMsg = "Invalid form_config json set in database"
        this.toastVariant = "danger"
        this.showToast = true
      }
    },
    /**
     * closecustomFormBuildEditModal
     */
    closecustomFormBuildEditModal () {
      try {
        this.showModelcustomFormBuildEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closecustomFormBuildEditModal() and Exception:", err.message)
      }
    },
    viewForm (cfbId) {
      window.open(`/Activity/${cfbId}`)
    }
  }
}
</script>
